import React from 'react'
import Helmet from 'react-helmet'
import { Timeline, TimelineItem }  from 'vertical-timeline-component-for-react';
import Layout from '../components/layout'
import ShowMore from 'react-show-more-button';


// import Lightbox from 'react-images'
import Gallery from '../components/Gallery'

// import form1 from '../assets/images/Murphy_patient_intake_form.pdf'
// import form2 from '../assets/images/Murphy_confidentiality_fee_agreement.pdf'

class HomeIndex extends React.Component {

    render() {

        const siteTitle = "Mary Gocke"
        const siteDescription = "Integrative and Functional Medicine Nutritionist"

        return (
            <Layout>
                <Helmet>
                        <title>{siteTitle}</title>
                        <meta name="description" content={siteDescription} />
                </Helmet>

                <div id="main">

                    <section id="one">
                        <header className="major">
                            <h2>For over 30 years Mary has been using an integrative, scientific, and creative
                            approach to teach, treat, and heal people with chronic and acute conditions in a
                            variety of settings.</h2>
                        </header>
                        <p>Mary's approach has successfully integrated food, nutrition science and
                        advanced communication skills to treat and heal people with chronic illnesses and
                        acute conditions. Her vast knowledge of nutrition, biochemistry, food,
                        environmental and lifestyle factors, genetics, mental health, and her ability to
                        connect and effectively communicate, along with her insatiable desire to learn,
                        keep Mary at the cutting edge of healthcare.</p>

                        <p>
                        A registered dietitian, rapid resolution therapy specialist, researcher and lifestyle counselor,
                        Mary’s emphasis is on therapies that incorporate:</p>
                        <ul class="services-list">
                            <li>Functional and medicinal foods</li>
                            <li>Hypnotherapy</li>
                            <li>Rapid Resolution Therapy – multi-level communication</li>
                            <li>Herbal, homeopathic, vitamin and mineral supplementation</li>
                            <li>Intravenous and parenteral therapies</li>
                            <li>Meditation</li>
                            <li>Exercise and movement</li>
                            <li>Body composition analysis</li>
                            <li>Nutrigenomics and nutrigenetics</li>
                            <li>Detoxification and elimination programs</li>
                        </ul>

                        <p>Mary’s clients range from people with specific, sometimes life threatening,
                        medical conditions, to individuals whose health is generally good, but who want to
                        improve their physical or emotional wellbeing.</p>

                        <ShowMore className='showmore-button' maxHeight={70}>

                        <p>As an experienced functional medicine nutritionist and registered dietitian,
                        Mary has worked professionally with physicians as a critical adjunct to their
                        practices. She has collaborated with the best physicians and scientists at prestigious
                        integrative medicine centers. Mary spent 15 years treating patients living with
                        AIDS and published research on reversing the wasting syndrome associated with
                        AIDS. She has also participated in grants aimed at bringing functional nutrition
                        into hospitals. Since high school Mary has practiced yoga, meditation and studied
                        the time honored Ayurvedic principles of health.</p>

                        <p>Mary received her master’s training at New York University in Human Nutrition
                        and Food Science and completed her internship in Nutrition at New York –
                        Presbyterian Hospital. Mary has been featured in national newspapers, trending
                        magazines and appeared on radio and national TV. Her clients range from
                        teenagers, celebrities, athletes, World leaders, to the underserved.</p>

                        <p>Overall, Mary's integrated approach, vast knowledge, effective communication 
                        skills, and commitment to ongoing learning make her a highly capable nutritionist 
                        and hypnotherapist, equipped to address the complex healthcare needs of her clients.</p>
                        
                        </ShowMore>

                    </section>

                    <section id="two">
                            <h2 className='section-title'>Accomplishments</h2>

<Timeline lineColor={'#ddd'}>

<TimelineItem
  key="001"
  dateText="2018 – Present"
  style={{ color: '#0677a4' }}
  dateInnerStyle={{ background: '#0677a4' }}
  >
    <h3>Founder of The Human Frame, LLC</h3>
    <h4>Virtual nutrition counseling including a form of conversational hypnotherapy</h4>
  </TimelineItem>

<TimelineItem
  key="002"
  dateText="2012 – 2018"
  style={{ color: '#0677a4' }}
  dateInnerStyle={{ background: '#0677a4' }}
  >
    <h3>Director of Nutrition and Director of the Blum Kitchen</h3>
    <h4>Blum Center for Health, Rye-Brook, NY</h4>
  </TimelineItem>

<TimelineItem
  key="003"
  dateText="2009 – 2012"
  style={{ color: '#0677a4' }}
  dateInnerStyle={{ background: '#0677a4' }}
  >
    <h3>Integrative and Functional Nutritionist</h3>
    <h4>Continuum Center for Health and Healing, Beth Israel Hospital, NYC, NY</h4>
  </TimelineItem>

  <TimelineItem
  key="004"
  dateText="2008 – 2011"
  style={{ color: '#0677a4' }}
  dateInnerStyle={{ background: '#0677a4' }}
  >
    <h3>Integrative and Functional Nutritionist</h3>
    <h4>Greenwich Hospital Center for Integrated Health, Greenwich, CT</h4>
  </TimelineItem>

  <TimelineItem
  key="005"
  dateText="1989 – 2000"
  style={{ color: '#0677a4' }}
  dateInnerStyle={{ background: '#0677a4' }}
  >
    <h3>Co-founder of Nutritional Concepts, Inc.</h3>
    <h4>Provided nutritional services to people living with AIDS, NYC, NY</h4>
  </TimelineItem>

  <TimelineItem
  key="006"
  dateText="1985 – 1989"
  style={{ color: '#0677a4'}}
  dateInnerStyle={{ background: '#0677a4' }}
  className='lastitem'
  >
    <h3>Staff Dietitian</h3>
    <h4>NYU Medical Center, NYC, NY</h4>
  </TimelineItem>

</Timeline>



                    </section>

{/*
                    <section id="three">
                            <h2 className='section-title'>Overview</h2>

                            <h3 class="overview-item-title">Certified Yoga Instructor (1990-present)</h3>
                            <p class="overview-item-description first">Teaching the time-honored tradition of yoga postures, breathing techniques and
                            meditation to all ages. Successfully integrated yoga into school curriculums.
                            Trained by world-renowned guru Alan Finger. Owned and operated 2 yoga
                            studios.</p>

                            <h3 class="overview-item-title">Research - HIV/AIDS</h3>
                            <p class="overview-item-description">Coordinated a series of clinical trials to correct nutritional complications associated
                            with HIV/AIDS Wasting Syndrome. Peer reviewed studies published.</p>
                            <ul>
                                <li class="overview-item">Biotechnology General, Inc., East Brunswick, NJ (1989-2002)</li>
                                <li class="overview-item">RJL Systems, Inc., NYC, NY (1989-2000)</li>
                                <li class="overview-item">Bristol-Myers Squibb, Princeton, NJ (1999)</li>
                                <li class="overview-item">Unimed Pharmaceuticals, Marietta, GA (1998)</li>
                            </ul>

                            <h3 class="overview-item-title">Clinical – HIV/AIDS</h3>
                            <p class="overview-item-description">Starting in 1985 through 2000 designed, implemented, and marketed cutting-edge
                            nutrition programs for people living with HIV/AIDS.</p>
                            <ul>
                                <li class="overview-item">Co-founder of Nutritional Concepts, Inc., providing various nutrition
                                programs for people living with HIV/AIDS; NYC, NY (1989-2000)</li>
                                <li class="overview-item">Presented nutrition protocols at International HIV/AIDS Conference (1990)</li>
                                <li class="overview-item">Nutritionist at various home-care companies which provided intravenous
                                support for HIV/AIDS clients in their homes (1987-2000)</li>
                            </ul>

                            <h3 class="overview-item-title">Research – Heart Disease</h3>
                            <p class="overview-item-description">Laboratory assistant analyzing blood lipids in the Greek population,
                            groundbreaking study showing the benefits of monounsaturated fats in the
                            Mediterranean diet</p>
                            <ul>
                                <li class="overview-item">University of Miami School of Medicine, Nutrition Division, Department of
                                Epidemiology and Public Health (1982-1983)</li>
                            </ul>

                    </section>
*/}

                    <section id="four">
                            <h2 className='section-title'>Training</h2>

<Timeline lineColor={'#ddd'}>

<TimelineItem
  key="001"
  dateText="2023"
  style={{ color: '#0677a4' }}
  dateInnerStyle={{ background: '#0677a4' }}
  >
    <h3>Rapid Resolution Therapy</h3>
    <h4>Technique developed by Jon Connelly, PhD, MSW, LCSW</h4>
  </TimelineItem>

<TimelineItem
  key="002"
  dateText="2021"
  style={{ color: '#0677a4' }}
  dateInnerStyle={{ background: '#0677a4' }}
  >
    <h3>Institute for Trauma Informed Hypnotherapy</h3>
    <h4>Trained with other healthcare professionals in clinical hypnosis interventions</h4>
  </TimelineItem>

<TimelineItem
  key="003"
  dateText="1998"
  style={{ color: '#0677a4' }}
  dateInnerStyle={{ background: '#0677a4' }}
  >
    <h3>L’Academie de Cuisine</h3>
    <h4>Considered one of the top cooking schools in America</h4>
  </TimelineItem>

  <TimelineItem
  key="004"
  dateText="1986 – 1987"
  style={{ color: '#0677a4' }}
  dateInnerStyle={{ background: '#0677a4' }}
  >
    <h3>New York University, New York, NY</h3>
    <h4>Masters Program in Nutrition Sciences</h4>
  </TimelineItem>

  <TimelineItem
  key="005"
  dateText="1985 – 1986"
  style={{ color: '#0677a4'}}
  dateInnerStyle={{ background: '#0677a4' }}
  >
    <h3>New York Hospital, New York, NY</h3>
    <h4>Internship Program for Registered Dietitians</h4>
  </TimelineItem>

  <TimelineItem
  key="006"
  dateText="1981 – 1985"
  style={{ color: '#0677a4' }}
  dateInnerStyle={{ background: '#0677a4' }}
  className='lastitem'
  >
    <h3>University of Florida, Gainesville, FL</h3>
    <h4>Bachelor of Science in Human Nutrition and Food Service</h4>
  </TimelineItem>

</Timeline>



                    </section>

                    <section id="five">
                        <h2 className='section-title'>Get In Touch</h2>
                        <p>Mary is a skilled professional offering a unique combination of nutrition counseling and hypnotherapy. She offers a 15-minute free consultation via telehealth as a convenient way for potential clients to learn more about Mary's expertise and approach before committing to a full session.</p>
                        <div className="row">
                            <div className="8u 12u$(small)">
                            </div>
                            <div className="4u 12u$(small)">
                                <ul className="labeled-icons">
                                <li>
                                        <h3 className="icon fa-envelope-o"><span className="label">Email</span></h3>
                                        <a href="mailto:mary@mary-gocke.com">mary@mary-gocke.com</a>
                                    </li>
                                    <li>
                                        <h3 className="icon fa-mobile"><span className="label">Phone</span></h3>
                                        <a href="tel:914-261-8870">(914) 261 8870</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>

                </div>

            </Layout>
        )
    }
}

export default HomeIndex
